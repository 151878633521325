<template>
  <div>
    <template v-if="isLoad">
      <!-- BEGIN breadcrumb -->
      <ol class="breadcrumb float-xl-end">
        <li class="breadcrumb-item"><a href="/app/dashboard">Home</a></li>
        <li class="breadcrumb-item"><a href="/app/clientes">Clientes</a></li>
        <li class="breadcrumb-item">Edição de Clientes</li>
      </ol>
      <h1 class="page-header">Detalhes do cliente</h1>
      <panel title="Dados do cliente" :noButton="true">
        <form @submit.prevent="onFormSubmit">
          <label class="form-label">Nome:</label>
          <input
            class="form-control"
            required
            type="text"
            v-model="cliente.nome"
          />
          <div class="w-100 d-flex justify-content-between mt-3">
            <button
              @click="() => $router.push({ name: 'cliente.listagem' })"
              class="me-2 rounded btn btn-danger btn-sm"
            >
              Voltar
            </button>
            <button
              v-if="checkFuncionalidade(9)"
              :noButton="true"
              type="submit"
              class="me-2 rounded btn btn-warning btn-sm"
            >
              <i class="fa fa-edit me-2" />Atualizar
            </button>
          </div>
        </form>
      </panel>

      <panel v-if="checkFuncionalidade(11)" :noButton="true">
        <div
          slot="header"
          class="w-100 d-flex align-items-center justify-content-between"
        >
          <span>Listagem de produtos vinculados ao cliente</span>
          <button
            v-if="checkFuncionalidade(12)"
            @click="detalhesProduto(undefined)"
            class="btn btn-success"
          >
            Novo Produto
          </button>
        </div>
        <vue-good-table
          :columns="columns"
          :rows="cliente.produtos"
          :lineNumbers="false"
          :styleClass="'vgt-table striped'"
          :search-options="{ enabled: true, placeholder: 'Pesquisar' }"
          :pagination-options="pageOptions"
        >
          <template slot="table-row" slot-scope="props">
            <span v-if="props.column.field == 'codProduto'">
              {{ props.row.cod_produto.toString().padStart(4, '0') }}
            </span>
            <span v-if="props.column.field == 'nome'">
              {{ props.row.nome }}
            </span>
            <span v-if="props.column.field == 'nomeclatura'">
              {{ props.row.nomenclatura }}
            </span>
            <span v-if="props.column.field == 'qtd'">
              {{ props.row.qtd_caixa }}
            </span>
            <span v-if="props.column.field == 'acao'">
              <button
                @click="detalhesProduto(props.row.id)"
                class="btn btn-secondary"
              >
                Visualizar
              </button>
            </span>
          </template>
        </vue-good-table>
      </panel>

      <modal-produto
        :produto-id="produtoId"
        :reloadForm="loadItens"
        :cliente_id="Number($route.params.id)"
      />
    </template>
    <template v-else>
      <div class="loading"></div>
    </template>
  </div>
</template>

<script>
import axios from 'axios'
import ModalProduto from './components/ModalProduto.vue'
import CheckFunc from '../../../mixins/CheckFunc'
export default {
  components: {
    ModalProduto
  },
  data() {
    return {
      isLoad: false,
      cliente: {},
      produtoId: undefined,
      columns: [
        {
          label: 'Cod. Produto',
          field: 'codProduto'
        },
        {
          label: 'Nome',
          field: 'nome'
        },
        {
          label: 'Nomeclatura',
          field: 'nomeclatura'
        },
        {
          label: 'Qtd. Caixa',
          field: 'qtd'
        },
        {
          label: 'Ações',
          field: 'acao',
          width: '150px'
        }
      ],
      rows: []
    }
  },
  mixins: [CheckFunc],
  methods: {
    detalhesProduto(id) {
      this.produtoId = id
      setTimeout(() => {
        this.$bvModal.show('detalhes-produto')
      }, 200)
    },
    async onFormSubmit() {
      await axios
        .put(`cliente/${this.$route.params.id}`, {
          nome: this.cliente.nome
        })
        .then(res => {
          return res.data
        })
        .then(res => {
          this.$notify({
            group: 'top-center',
            title: `Cliente atualiado!`,
            text: res.mensagem,
            type: 'success'
          })
          this.$router.push({ name: 'cliente.listagem' })
        })
    },
    async loadItens() {
      this.isLoad = false
      await axios
        .get(`cliente/${this.$route.params.id}`)
        .then(res => {
          return res.data.dados
        })
        .then(res => {
          this.cliente = res
          this.isLoad = true
        })
    }
  },
  computed: {
    pageOptions() {
      return {
        enabled: true,
        mode: 'records',
        perPage: 10,
        position: 'bottom',
        perPageDropdown: [10, 50, 100],
        dropdownAllowAll: false,
        setCurrentPage: 1,
        jumpFirstOrLast: true,
        firstLabel: 'Primeira Página',
        lastLabel: 'Última Página',
        nextLabel: 'Próxima',
        prevLabel: 'Anterior',
        rowsPerPageLabel: 'Linhas por página',
        ofLabel: 'de',
        pageLabel: 'página', // for 'pages' mode
        allLabel: 'Todos',
        infoFn: params => `Página atual ${params.firstRecordOnPage}`
      }
    }
  },
  mounted() {
    this.loadItens()
  }
}
</script>
